import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js"

export default function Index()
{
    return (
        <Layout>
            This is an overview of what this site is all about.
            <Link to="katas"> 
                <h3> Katas are here </h3>
            </Link>
        </Layout>
    );
}
